<template>
	<div class="enterprise">
		<div class="breadcrumbTop">
			<a-breadcrumb>
				<a-breadcrumb-item @click='$router.back()'>自定义设置</a-breadcrumb-item>
				<a-breadcrumb-item><a href="">离职类型</a></a-breadcrumb-item>
			</a-breadcrumb>
			<div class="tips_top_box">
				<p> 添加的类别在员工详情页-奖惩记录的奖惩类别字段中应用</p>
			</div>
			<div style="text-align: right;">
				<a-button @click='mergeVisible=true' class='pointerCancel'>添加离职
				</a-button>
			</div>
			<div class="add_info" >
				<div style="flex: 1;">
					<div class="FieldSettingsBox">
						<div class="FieldSettingsBoxTitle ">
						<span class="show_type" >离职类型</span> <span class="cq_color" @click='addTypeVisible=true' style="text-align: right;">+添加</span>
						</div>
						<div :class="['FieldSettingsBoxTitle',titelIndex==element.value?'FieldSettingsBoxActive':''] " @click="changeList(element.value)"
							v-for="element in type_list" :key="element.value">
							<span>
								&nbsp; &nbsp;
							</span>
							<span>
								{{element.label}}
							</span>
							<span class="cq_color edit_item check" v-if="element.can_edit==1"  @click='editInfo(element)'>编辑</span>
							<a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="delCate(element.value)"
									@cancel="cancel">
									<span v-if="element.can_delete==1" class="cq_error edit_item del">删除</span>
								</a-popconfirm>
						</div>
					</div>
				</div>
				<span style=" width: 2px;background: #eee;"></span>
				<div style="flex: 3;margin-left: 12px;">
					<div class="FieldSettingsBox">
						<div class="FieldSettingsBoxTitle FieldSettingsBoxHeader">
							<span>
								&nbsp; &nbsp;
							</span> <span>类别名称</span> <span></span> <span style="color: #666;width: 120px;">启用/禁用</span>
						</div>
						<!-- <draggable v-model="all_list" chosenClass="chosen" forceFallback="true" group="people"
							animation="1000" @start="onStart" @end="onEnd">
							<transition-group> -->
								<div  :draggable="true"
                                    @dragstart="dragstart($event, -1, index)"
                                    @dragover="dragover"
                                    @dragenter="dragenter($event, -1, index)"
                                    @dragend="dragend($event, -1, index)"
                                    @drop="drop" v-show="element.status==1" class="FieldSettingsBoxTitle"
									v-for="(element,index) in all_list"  >
									<span>
										<a-icon type="unordered-list" />
									</span>
									<span>
										{{element.name}}
									</span>
									<span> </span>
									<span style="width: 120px;">
									<a-switch :disabled="element.can_forbid==-1" @click='change_forbid(element.id)' v-model:checked="element.is_check" />
									<a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="delItem(element.id)"
										@cancel="cancel">
												<span v-if="element.can_delete==1" style="margin-left: 12px;" class="cq_error" >删除</span>
									</a-popconfirm>
									</span>
								</div>
							<!-- </transition-group>
						</draggable> -->
						
						<div v-show="element.status==-1" class="FieldSettingsBoxTitle"
							v-for="element in all_list" >
							<span>
								<a-icon type="unordered-list" />
							</span>
							<span>
								{{element.name}}
							</span>
							<span> </span>
							<span style="width: 120px;">
							<a-switch :disabled="element.can_forbid==-1" @click='change_forbid(element.id)' v-model:checked="element.is_check" />
							
							<a-popconfirm title="是否确认删除?" ok-text="是" cancel-text="否" @confirm="delItem(element.id)"
								@cancel="cancel">
										<span v-if="element.can_delete==1" style="margin-left: 12px;" class="cq_error" >删除</span>
							</a-popconfirm>
							</span>
						</div>
					</div>
				</div>

			</div>

		</div>
		<a-modal v-model:visible="addTypeVisible" :title="addType=='add'?'新增类型':'编辑'" @ok="addOk">
				    <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit" >
				        <a-form-item label="离职类型">
				            <a-input  v-model:value="name" />
				        </a-form-item>
				    </a-form>
				</a-modal>
				
		<a-modal v-model:visible="mergeVisible" :title="editType=='add'?'新增类型':'编辑'" @ok="mergeOk">
		    <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit" >
		        <a-form-item label="离职类型" v-if="editType=='add'">
					<a-select v-model:value="formstatus.pid" placeholder="请选择所属分类">
							<a-select-option v-for='(item,index) in type_list' :value="item.value" :key='index'>
								{{item.label}}
							</a-select-option>
						</a-select>
		        </a-form-item>
		        <a-form-item label="离职原因">
		            <a-input  v-model:value="formstatus.name" />
		        </a-form-item>
		    </a-form>
		</a-modal>
	</div>
</template>

<script>
	import {
		editCustomSettingCate,
		getCustomSettingInfo,
		getCustomSettingDetail,
		changeCustomSettingStatic,
		changeCustomSettingStatus,
		sortCustomSetting,
		addCustomSettingCate,
		getCustomSettingCateSelect,addCustomSetting,delCustomSetting,delCustomSettingCate,changeEmployeeAttributeStatus
	} from '@/api/enterprise.js'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';

	
	export default {
		components: {
		
		},
		data() {
			return {stop_index: 0,
				stop_approval_index: 0,
				name:'',
				addType:'add',
				addTypeVisible:false,
				mergeVisible:false,
				type_list: [],
				all_list: [],
				visit2: true,
				labelColEdit: {
					span: 4
				},
				wrapperColEdit: {
					span: 20
				},
				wrapperCol: {
					span: 12
				},
				select_list: [],
				type: '',
				formstatus:{
					pid:''
				},
				titelIndex: '',
				editType:'add',
				editId:'add',
			}

		},
		created() {
			this.type = this.$route.query.type
			this.getIndustry()
		},
		methods: {
			dragstart(e, ind) {
			    // e.preventDefault()
			},
			drop(e) {
			    e.preventDefault()
			},
			// 拖拽位置
			dragenter(e, ind, index) {
			    e.preventDefault()
			    if (ind != -1) {
			        this.stop_index = ind;
			    }
			    this.stop_approval_index = index
			},
			dragover(e) {
			    e.preventDefault()
			},
			// 拖拽完成
			dragend(e, ind, index) {
			   let list = this.all_list;
			   let move_data = list[index];
			   list.splice(index, 1)
			   list.splice(this.stop_approval_index, 0, move_data)
			   this.all_list = list;
			   this.onEnd()
			
			},
			cancel(){},
			editInfo(item){
				this.addType = 'edit'
				this.name = item.label
				this.editId = item.value
				this.addTypeVisible=true
			},
			addOk(){
				if(this.addType=='add'){
					addCustomSettingCate({data:{
						type: this.type,
						name:this.name
					},info:true}).then(res=>{
						console.log(2)
						this.addTypeVisible=false
						this.getIndustry()
					})
				}else{
					editCustomSettingCate({data:{
						id: this.editId,
						type: this.type,
						name:this.name
					},info:true}).then(res=>{
						console.log(1)
						this.getIndustry()
						this.addTypeVisible=false
					})
				}
			},
			getInfo(item){
				this.formstatus =JSON.parse(JSON.stringify(item)) ;
				this.formstatus.pid =this.titelIndex 
				this.editType ='adit'
				this.mergeVisible= true
			},
			change_forbid(id) {
				
				changeCustomSettingStatus({
					data: {
						id: id
					}
				}).then(res => {
					message.success('操作成功');
					this.changeList(this.titelIndex)
				})
			},
			
			onStart() {
				this.drag = true;
			},
			onEnd() {
				this.drag = false;
				let sort = []
				this.all_list.forEach(item => {
					sort.push(item.id)
				})
				sortCustomSetting({
					data: {
						sort: sort.join(',')
					}
				}).then(res => {
					message.success('操作成功');
					this.changeList(this.titelIndex)
				})
			},
			delItem(item){
				delCustomSetting({
					data: {
						id: item,
					}
				}).then(res => {
				this.getIndustry()
				})
			},
			delCate(item){
				delCustomSettingCate({
					data: {
						id: item,
					}
				}).then(res => {
					this.changeList(this.titelIndex)
				})
			},
			
			changeList(type){
				this.formstatus.pid = type
				getCustomSettingDetail({
					data: {
						type: this.type,
						pid:type,
					}
				}).then(res => {
					res.data.list.content.forEach(item=>{
						item.is_check = item.status==1?true:false
					})
						this.titelIndex = type
					this.all_list = res.data.list.content
				})
			},
			mergeOk(){
				
				addCustomSetting({
					data: {
						type: this.type,
						...this.formstatus
					}
				}).then(res => {
					this.mergeVisible = false
					this.formstatus={}
					this.editType='add'
					this.changeList(this.titelIndex)
				})
			},
			getIndustry() {
				getCustomSettingCateSelect({
					data: {
						type: this.type
					}
				}).then(res => {
					this.type_list = res.data.list
					if(!this.titelIndex){
						this.titelIndex = res.data.list[0].value
						this.formstatus.pid =res.data.list[0].value
					}
					this.changeList(this.titelIndex)
				})
			},
			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");
	@import 'info.less';

	.breadcrumbTop {
		flex: 1;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.FieldSettingsBox {
		margin-top: 12px;
		.edit_item{
			color: #fff;
		}
	}
	

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 4;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 1;
		}

		span:nth-child(4) {
			color: #fff;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
		
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		.check {
			color: @cq_color;
		}
		.del {
			color: red;
		}

	}

	.FieldSettingsBoxActive {
		border-right: 3px solid @cq_color;
		background: #f8f8f9;
		
		
	}
	.add_info{
		margin-top: 12px;
		display: flex;border: 1px solid #eee;padding: 12px;
	}
	.show_type{
		border-left: 2px solid #0079FF;padding-left: 12px;
	}
</style>
